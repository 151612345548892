import { useEffect, useState, FC } from "react";
import { useLogout } from "../services/auth.service";
import cache, { data } from "../services/cache.service";
import { Account } from "../types/server.type";
import Enroll, { EnrollHandlers } from "../components/enroll.component";
import { TellerConnectEnrollment } from "teller-connect-react";
import userService from "../services/user.service";
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box, Button, Card, CardBody, CardHeader, Heading, StackDivider, Text, Icon,
  Table, TableCaption, TableContainer, Tbody, Td, Tr, Divider, ButtonGroup,
  HStack, Alert, AlertIcon,
  useToast,
  UseToastOptions,
  Editable,
  EditablePreview,
  EditableInput,
  Flex,
  Tooltip,
  SkeletonText,
  CircularProgress
} from "@chakra-ui/react";
import {
  ArrowLeftRight,
  ArrowRepeat, Bank, CheckCircleFill, EyeFill, EyeSlashFill,
  PenFill, Trash3Fill, Wrench, XCircleFill
} from "react-bootstrap-icons";
import utils, { logger, REPAIR_MODE } from "../common/utils";
import { useModalConfirmation } from "../components/useModalConfirmation";
import RepairService from "../services/repair.service";

const Loader = () => (
  <Box padding='6' boxShadow='lg' bg='white'>
    <CircularProgress isIndeterminate color="gray.300" />
    <SkeletonText mt='4' noOfLines={6} spacing='5' skeletonHeight='2' />
  </Box>
);

const getReadableAccountType = (code: string): string => {
  const words = code.split('_');
  words.forEach((_, i) => words[i] = words[i].toLocaleUpperCase());
  return words.join(' ');
}

const defaultToastOptions: UseToastOptions = {
  isClosable: true,
  position: 'top',
  duration: 9000,
};

const hideAccountMessage = `Hiding an account untracks all transactions under that account for budgeting.`;

interface AccountHandlers {
  onNicknameAccount: (account: Account, nickname?: string) => void
  onHideAccount: (account: Account, hide: boolean) => void
  onRemoveAccount: (account: Account) => void
}
const AccountInfo: FC<Account & AccountHandlers> = (props) => {
  const { name, currency, balance, lastFour, status, hidden } = props;
  const { onHideAccount, onRemoveAccount, onNicknameAccount } = props;
  const statusLabel = status ? <>Connected <Icon color='green' as={CheckCircleFill} /></> : <>Disconnected <Icon color='red' as={XCircleFill} /></>;
  const accountType = getReadableAccountType(props.subtype);
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading size='sm' textTransform='uppercase'>
            {name}{'   '}
          </Heading>
          <HStack spacing={8}
            divider={<StackDivider borderColor='gray.200' />}>
            <Text width='150px' pt='4' as='b' color='gray.600' fontSize='sm'>Balance: {utils.GetAmountAsCurrency(balance?.available || balance?.ledger || 0, currency)}{ }</Text>
            <Text width='150px' pt='4' as='b' color='gray.600' fontSize='sm'>{accountType}</Text>
            <Text width='150px' pt='4' as='b' color='gray.600' fontSize='sm'>Status: {statusLabel}</Text>
          </HStack>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <TableContainer>
          <Table variant='simple'>
            <TableCaption placement='top'>Account Details</TableCaption>
            <TableCaption placement='bottom'>*** {lastFour}</TableCaption>
            <Tbody>
              <Tr>
                <Td>
                  <ButtonGroup variant='outline' spacing='6'>
                    <Button leftIcon={<PenFill />} onClick={() => onNicknameAccount(props)}>Set Nickname</Button>
                    {hidden ? <Button leftIcon={<EyeFill />} onClick={() => onHideAccount(props, false)}>Show Account</Button> :
                      <Tooltip placement='bottom' label={hideAccountMessage} openDelay={200}>
                        <Button leftIcon={<EyeSlashFill />} onClick={() => onHideAccount(props, true)}>Hide Account</Button>
                      </Tooltip>}
                    <Button colorScheme="red" leftIcon={<Trash3Fill />} onClick={() => onRemoveAccount(props)}>Remove Account</Button>
                  </ButtonGroup></Td>
              </Tr>
              <Tr>
                <Td>{`Account Type: ${accountType}`}</Td>
              </Tr>
              {balance?.available !== 0 && balance?.available && <Tr>
                <Td>Available Balance</Td>
                <Td isNumeric>{utils.GetAmountAsCurrency(balance.available, currency)}</Td>
              </Tr>}
              {balance?.ledger !== 0 && balance?.ledger &&
                <Tr>
                  <Td>Current Balance</Td>
                  <Td isNumeric>{utils.GetAmountAsCurrency(balance.ledger, currency)}</Td>
                </Tr>
              }
            </Tbody>
          </Table>
        </TableContainer>
      </AccordionPanel>
    </AccordionItem>
  )
}

interface AccountsProps extends AccountHandlers, EnrollHandlers {
  accounts: Account[]
  name: string
  institution: string
  loadingEnrollment: boolean
  onRefreshEnrollment: (enrollementId: string, enrollmentName: string) => void
  onRenameEnrollment: (enrollementId: string, newName: string) => void
  onRecreateEnrollment: (prevEnrollementId: string,
    enrollment: TellerConnectEnrollment, environment: string) => void
}
const AccountsCard: FC<AccountsProps> = (props) => {
  const { name, institution, accounts, loadingEnrollment, onHideAccount,
    onRenameEnrollment, onRefreshEnrollment, onRecreateEnrollment, onRemoveAccount,
    onNicknameAccount, ...enrollHandlers } = props;

  const accountHandlers = {
    onHideAccount,
    onRemoveAccount,
    onNicknameAccount,
  };

  const { enrollmentId, healthy } = accounts[0];
  return (
    <Card>
      <CardHeader>
        <Heading size='md'>
          <Box>
            <Flex>
              <Editable defaultValue={name} submitOnBlur={false} onSubmit={(val) => onRenameEnrollment(enrollmentId, val)}>
                <EditablePreview />
                <EditableInput />
                {' '}
                <Icon as={Bank} />
              </Editable>
            </Flex>
            {/* TODO(UX) make "Please re-enroll" a link button to re-enroll */}
            {!healthy && (<Text fontSize='xs'><Icon color='red' as={XCircleFill} /> The connection to this institution is broken. Please re-enroll</ Text >)}
            <Divider />
            {/* TODO: include tooltop for this button: fix existting accounts under this institution. Perhaprs only show this is enrollment is unhealhty */}
            <Enroll
              name={'Reconnect Institution'}
              loading={loadingEnrollment}
              enrollmentId={enrollmentId}
              {...enrollHandlers}
              colorScheme='teal' variant='outline' ml={5}
              leftIcon={<Icon as={ArrowLeftRight} />}
            />
            <Tooltip placement='bottom' label={`Pull updated transactions from your institution`} openDelay={200}>
              <Button onClick={() => onRefreshEnrollment(enrollmentId, name)} leftIcon={<Icon as={ArrowRepeat} />}
                colorScheme='teal' variant='outline' ml={5}>Update transactions</Button>
            </Tooltip>
            <Tooltip placement='bottom' label={`Reconnect with your institution to add accounts`} openDelay={200}>
              <Enroll
                name={'Update Accounts'}
                loading={loadingEnrollment}
                institution={institution}
                {...enrollHandlers}
                onTellerSuccess={(enrollment, environment) => {
                  onRecreateEnrollment(enrollmentId, enrollment, environment);
                }}
                colorScheme='teal' variant='outline' ml={5}
                leftIcon={<Icon as={ArrowLeftRight} />}
              />
            </Tooltip>
          </Box>
        </Heading>
      </CardHeader>
      <CardBody>
        <Accordion defaultIndex={[]} allowMultiple>
          {accounts.map((acct, idx) => <AccountInfo {...accountHandlers} key={idx} {...acct} />)}
        </Accordion>
      </CardBody>
    </Card>
  );
}

interface ToastInfo {
  successTitle?: React.ReactNode;
  errorTitle?: React.ReactNode;
  loadingTitle?: React.ReactNode;
}

const popToast = (toast: ReturnType<typeof useToast>, promise: Promise<any>, info: ToastInfo) => {
  toast.promise(promise, {
    success: (name) => {
      return ({
        title: info.successTitle || 'Operation successful',
        description: name,
        ...defaultToastOptions,
      })
    },
    error: (errorMessage) => {
      return ({
        title: info.errorTitle || 'Operation failed',
        description: errorMessage?.message,
        ...defaultToastOptions,
      })
    },
    loading: { title: info.loadingTitle || 'Processing request..', ...defaultToastOptions },
  });
};

const Profile: FC<{}> = () => {
  const logout = useLogout();
  const toast = useToast();
  const [hasAccounts, setAccounts] = useState<Account[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [softLoader, setSoftLoader] = useState<boolean>(false);
  const firstName = cache.getCurrentUser().firstName;
  const [getUserConfirmation, ConfirmationModal] = useModalConfirmation();

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    let mounted = true;
    data.getAccounts()
      .then(result => {
        if (mounted) {
          if (result.logout) {
            logout();
            return;
          }
          setAccounts(result.data ?? []);
          if (!result.success) {
            setErrorMessage(result.message);;
          }
        }
      });
    return () => { mounted = false };
  }, [])

  const handleTellerStart = () => {
    setSoftLoader(true);
  };

  const handleTellerExit = () => {
    setErrorMessage('Connection to accounts was cancelled.');
    setSoftLoader(false);
  };

  const handleTellerEnrollmentSuccess = (enrollment: TellerConnectEnrollment, environment: string) => {
    const enrollPromise = new Promise((resolve, reject) => {
      userService.enroll(enrollment, environment).then((result) => {
        if (result.logout) {
          logout();
          reject('Not signed in');
          return;
        }

        if (result.success && result.data) {
          setAccounts(result.data.accounts);
          setSoftLoader(false);
          const { name } = enrollment.enrollment.institution;
          resolve(`${name} accounts have been enrolled`);
          return;
        }

        setErrorMessage(result.message);
        setSoftLoader(false);
        reject(result.message);
      });
    });

    popToast(toast, enrollPromise, {
      successTitle: 'Enrollment Successful',
      errorTitle: 'Enrollment failed',
      loadingTitle: 'Enrolling..',
    });
  };

  const handleTellerEnrollmentUpdateSuccess =
    (prevEnrollmentId: string, enrollment: TellerConnectEnrollment, environment: string) => {
      const enrollPromise = new Promise((resolve, reject) => {
        userService.enroll(enrollment, environment, prevEnrollmentId).then((result) => {
          if (result.success) {
            if (result.data) {
              setAccounts(result.data.accounts);
            }
            setSoftLoader(false);
            const { name } = enrollment.enrollment.institution;
            resolve(`${name} accounts have been re-enrolled`);
            return;
          }

          setErrorMessage(result.message);
          setSoftLoader(false);
          reject(result.message);
        });
      });

      popToast(toast, enrollPromise, {
        successTitle: 'Re-Enrollment Successful',
        errorTitle: 'Re-Enrollment failed',
        loadingTitle: 'Enrolling..',
      });
    };

  const handleFixEnrollment = (enrollmentId: string, enrollmentName: string) => {
    userService.refreshEnrollment(enrollmentId).then((result) => {
      if (result.logout) {
        logout();
        return;
      }

      if (result.success) {
        logger.log(`Successfully refreshed ${enrollmentName}`);
        cache.invalidate('accounts');
        return;
      }

      setErrorMessage(result.message);
    });
  }

  const handleEnrollmentRename = (enrollmentId: string, newName: string) => {
    const syncAccountsPromise = new Promise((resolve, reject) => {
      if (newName.length < 3 || newName.length > 25) {
        const msg = 'Invalid name length, min=3, max=25';
        reject(msg);
        setErrorMessage(msg);
        return;
      }

      userService.renameEnrollment({ enrollmentId, name: newName }).then((result) => {
        if (result.logout) {
          logout();
          reject('Not signed in');
          return;
        }

        if (result.success) {
          cache.invalidate('accounts');
          resolve(null);
          return;
        }

        setErrorMessage(result.message);
        reject(result.message);
      });
    });

    toast.promise(syncAccountsPromise, {
      success: { title: 'Rename Complete', ...defaultToastOptions },
      error: (errorMessage) => {
        return ({
          title: 'Rename failed',
          description: `${errorMessage}`,
          ...defaultToastOptions,
        })
      },
      loading: { title: 'Renaming enrollment..', ...defaultToastOptions },
    });
  }

  const removeAccount = (account: Account) => {
    const removeAccountPromise = new Promise((resolve, reject) => {
      userService.modifyAccount(account.id, 'DELETE').then((result) => {
        if (result.success) {
          cache.invalidate('accounts');
          resolve(`Account: '${account.name}' has been removed.`);
          return;
        }

        reject(result.message);
      });
    });

    popToast(toast, removeAccountPromise, {});
  }

  const handleRemoveAccount = (account: Account) => {
    getUserConfirmation({
      title: `Remove Account (${account.name})`,
      body: `This will remove this account and all it's transactions from Owo App. 
      You would have enroll this account again to get the transactions back. 
      Are you sure you want to continue?`,
      confirm: { name: 'Yes', handler: () => removeAccount(account) },
      reject: { name: 'No' },
      styling: { confirmationButtonColorScheme: 'red' }
    });
  }

  const toggleHideAccount = (account: Account, hide: boolean) => {
    const hideAccountPromise = new Promise((resolve, reject) => {
      userService.modifyAccount(account.id, 'HIDE', undefined, hide).then((result) => {
        if (result.logout) {
          logout();
          reject('Not signed in');
          return;
        }

        if (result.success) {
          cache.invalidate('accounts');
          resolve(hide ? 'Account hidden' : null);
          return;
        }

        reject(result.message);
      });
    });

    popToast(toast, hideAccountPromise, {});
  };

  const handleHideAccount = (account: Account, hide: boolean) => {
    if (!hide) {
      toggleHideAccount(account, false);
      return;
    }

    getUserConfirmation({
      title: `Hide Account (${account.name})`,
      body: `${hideAccountMessage}. You can change this anytime.\nAre you sure you want to proceed?`,
      confirm: { name: 'Yes', handler: () => toggleHideAccount(account, hide) },
      reject: { name: 'No' },
    });

  }

  const handleNicknameAccount = (account: Account, nickname?: string) => {
    logger.log(`Handle rename account ${account}`);
  }

  const handleRepairTransactions = () => {
    const repairTransactions = new Promise((resolve, reject) => {
      const repairService = RepairService();
      if (!repairService) {
        reject(`Cannot repair. Not allowed`);
        setErrorMessage(`This action is not allowed.`)
        return;
      }

      repairService.repairTransactions().then((result) => {
        if (result.success) {
          if (result.data && (result.data > 0)) {
            cache.invalidate('transactions');
          }

          resolve(`Transactions repaired`);
          return;
        }

        reject(result.message);
      });
    });

    popToast(toast, repairTransactions, {});
  }

  const handleSyncAllAccounts = () => {
    const syncAccountsPromise = new Promise((resolve, reject) => {
      userService.syncAccounts().then((result) => {
        if (result.logout) {
          logout();
          reject('Not signed in');
          return;
        }

        if (result.success) {
          cache.invalidate('accounts');
          resolve(null);
          return;
        }

        setErrorMessage(result.message);
        reject();
      });
    });

    toast.promise(syncAccountsPromise, {
      success: { title: 'Sync Complete', ...defaultToastOptions },
      error: (errorMessage) => {
        return ({
          title: 'Sync failed',
          description: `${errorMessage}`,
          ...defaultToastOptions,
        })
      },
      loading: { title: 'Processing sync..', ...defaultToastOptions },
    });
  };

  const accounts: { [enrollment: string]: Account[] } = {};
  for (const account of hasAccounts || []) {
    const enrollment = account.enrollmentId;
    if (!accounts[enrollment]) {
      accounts[enrollment] = [];
    }

    accounts[enrollment].push(account);
  }

  const accountsList = Object.keys(accounts).map((enrollment, idx) =>
    <Box key={idx}>
      <AccountsCard accounts={accounts[enrollment]}
        name={accounts[enrollment][0].enrollmentName || accounts[enrollment][0].institution.name} // every enrollment has at least one account
        institution={accounts[enrollment][0].institution.id}
        loadingEnrollment={softLoader}
        onTellerStart={handleTellerStart}
        onTellerIncomplete={handleTellerExit}
        onTellerSuccess={handleTellerEnrollmentSuccess}
        onRenameEnrollment={handleEnrollmentRename}
        onRefreshEnrollment={handleFixEnrollment}
        onRecreateEnrollment={handleTellerEnrollmentUpdateSuccess}
        onHideAccount={handleHideAccount}
        onRemoveAccount={handleRemoveAccount}
        onNicknameAccount={handleNicknameAccount}
      />
      <Divider />
    </Box>
  );

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{firstName}</strong>
          <Button onClick={handleLogout}>Logout</Button>
        </h3>
      </header>
      {ConfirmationModal}
      {errorMessage && <Alert status='error'> <AlertIcon />{errorMessage} </Alert>}
      {(hasAccounts) ?
        <div>
          <Text as='h2'>Accounts:</Text>
          {hasAccounts.length === 0 && <Alert status='warning'><AlertIcon /> No accounts found. Connect your accounts to start budgeting </Alert>}
          {REPAIR_MODE && <Button onClick={handleRepairTransactions}
            leftIcon={<Icon as={Wrench} />} colorScheme='orange' variant='outline' ml={5} mr={5}>Fix transactions</Button>}
          <Enroll
            name={hasAccounts.length > 0 ? 'Connect Another Account' : 'Connect Account'}
            loading={softLoader}
            onTellerStart={handleTellerStart}
            onTellerIncomplete={handleTellerExit}
            onTellerSuccess={handleTellerEnrollmentSuccess}
          />

          {hasAccounts.length > 0 &&
            <>
              <Divider />
              <Button ml={'15px'} onClick={handleSyncAllAccounts}
                leftIcon={<Icon as={ArrowRepeat} />} colorScheme='teal' variant='outline'>Update All Transactions</Button>
              <Divider />
              {accountsList}
            </>
          }
        </div> :
        <Loader />
      }
    </div >
  );
};

export default Profile;