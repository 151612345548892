import { useEffect } from "react";
import { Result } from "../common/api";
import { logger } from "../common/utils";
import { Account, CustomerInfo, ParentBucket, Transaction, TransactionList, UserInfo } from "../types/server.type";
import { useLogout } from "./auth.service";
import userService from "./user.service";
import { Optional } from "../types/type-utilities";
import { TellerParams } from "../types/user.type";

type CustomerInfoCacheKeys = 'userInfo' | 'accounts' | 'buckets' | 'transactions';
type CacheKey = CustomerInfoCacheKeys | 'sauce';

const cacheKeys: readonly CacheKey[] = [
    "accounts",
    "buckets",
    "sauce",
    "transactions",
    "userInfo",
];

const cache = {
    cacheUserInfo(userInfo: UserInfo) {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },

    cacheUserData(data: Optional<CustomerInfo, CustomerInfoCacheKeys>) {
        if (data.userInfo)
            localStorage.setItem("userInfo", JSON.stringify(data.userInfo));
        if (data.transactions)
            localStorage.setItem("transactions", JSON.stringify(data.transactions));
        if (data.buckets)
            localStorage.setItem("buckets", JSON.stringify(data.buckets));
        if (data.accounts)
            localStorage.setItem("accounts", JSON.stringify(data.accounts));
    },

    cacheSauce(data: TellerParams) {
        if (data.sauce) {
            localStorage.setItem("sauce", data.sauce);
        }

        if (data.mixer) {
            sessionStorage.setItem("mixer", data.mixer);
        }
    },

    cacheMixer(mixer: string) {
        sessionStorage.setItem("mixer", mixer);
    },

    cacheBucketMapping(bucketMapping: { [key: number]: string }) {
        localStorage.setItem("bucketmapping", JSON.stringify(bucketMapping));
    },

    cacheBucketStructure(bucketStructure: { [key: number]: number[] }) {
        localStorage.setItem("bucketstructure", JSON.stringify(bucketStructure));
    },

    invalidate(key: CacheKey) {
        localStorage.removeItem(key);
    },

    clear() {
        for (const cacheKey of cacheKeys) {
            localStorage.removeItem(cacheKey);
        }
        sessionStorage.clear();
    },

    getCurrentUser() {
        const userStr = localStorage.getItem("userInfo");
        if (userStr) return JSON.parse(userStr);

        return null;
    },

    getRegistration() {
        return localStorage.getItem("email");
    },

    cacheRegistration(email: string) {
        localStorage.setItem("email", email);
    },
};

export const data = {
    async getAccounts(): Promise<Result<Account[]>> {
        let accounts: Account[];
        const accountsString = localStorage.getItem("accounts");
        if (accountsString != null) {
            try {
                accounts = JSON.parse(accountsString);
                return { success: true, data: accounts, message: '' };
            }
            catch (error: any) {
                logger.log(': ');
                logger.log(error);
            }
        }

        return userService.getAccounts();
    },

    async getSauceAndMixer(): Promise<Result<TellerParams>> {
        const sauce = localStorage.getItem("sauce");
        const mixer = sessionStorage.getItem("mixer");
        if (sauce == null || mixer == null) {
            return new Promise((resolve) => { resolve(userService.getSauceAndMixer()); });
        }

        return { success: true, data: { sauce, mixer }, message: '' };
    },

    async getBucketMapping(): Promise<Result<{ [key: number]: string }>> {
        let bucketMapping: { [key: number]: string };
        const bucketMappingJSON = localStorage.getItem("bucketmapping");
        if (bucketMappingJSON != null) {
            try {
                bucketMapping = JSON.parse(bucketMappingJSON);
                return { success: true, data: bucketMapping, message: '' };
            }
            catch (error: any) {
                logger.log(error);
            }
        }

        return userService.getBucketMapping();
    },

    async getBuckets(): Promise<Result<ParentBucket[]>> {
        let buckets: ParentBucket[];
        const bucketsJSON = localStorage.getItem("buckets");
        if (bucketsJSON != null) {
            try {
                buckets = JSON.parse(bucketsJSON);
                return { success: true, data: buckets, message: '' };
            }
            catch (error: any) {
                logger.log(error);
            }
        }

        return userService.getBuckets({});
    },

    useSauce(dataSetter: (x: TellerParams) => void, errorSetter: (x: string) => void,
        { defaultErrorMessage, forceDefault }: { defaultErrorMessage: string, forceDefault: boolean }) {
        const logout = useLogout();
        useEffect(() => {
            let mounted = true;
            this.getSauceAndMixer()
                .then(result => {
                    if (mounted) {
                        if (result.logout) {
                            logout();
                            return;
                        }
                        if (result.success && result.data) {
                            dataSetter(result.data);
                            return;
                        }
                        errorSetter(forceDefault ? defaultErrorMessage : result.message);
                    }
                });
            return () => { mounted = false };
        }, []);
    },

    useTransactions(reactive: any[] = [], dataSetter: (x: TransactionList) => void, errorSetter: (x: string) => void,
        { defaultErrorMessage, forceDefault }: { defaultErrorMessage: string, forceDefault: boolean }) {
        const logout = useLogout();
        useEffect(() => {
            let mounted = true;
            (async (): Promise<Result<TransactionList>> => {
                let transactions: TransactionList;
                const transactionsString = localStorage.getItem("transactions");
                if (transactionsString != null) {
                    try {
                        transactions = JSON.parse(transactionsString);
                        return { success: true, data: transactions, message: '' };
                    }
                    catch (error: any) {
                        logger.log(error);
                    }
                }

                return userService.getTransactions();
            })().then(result => {
                if (mounted) {
                    if (result.logout) {
                        logout();
                        return;
                    }
                    if (result.success && result.data) {
                        dataSetter(result.data);
                        return;
                    }
                    errorSetter(forceDefault ? defaultErrorMessage : result.message);
                }
            });
            return () => { mounted = false };
        }, reactive);
    },
};

export function useDataGetter<T, P = any>(
    watchParams: any[] = [],
    apiGetterFunction: (...params: P[]) => Promise<Result<T>>,
    cacheName: 'transactions' | 'buckets' | 'accounts' | 'bucketmapping' | 'bucketstructure',
    dataSetter: (x: T) => void,
    errorSetter: (x: string) => void,
    { defaultErrorMessage, forceDefault, forceFetch }: { defaultErrorMessage: string, forceDefault: boolean, forceFetch?: boolean },
    ...params: P[]) {
    const logout = useLogout();
    useEffect(() => {
        let mounted = true;
        (async (): Promise<Result<T>> => {
            if (!forceFetch) {
                let data: T;
                const jsonString = localStorage.getItem(cacheName);
                if (jsonString != null) {
                    try {
                        data = JSON.parse(jsonString);
                        return { success: true, data, message: '' };
                    }
                    catch (error: any) {
                        logger.error(`Error while getting '${cacheName}' from cache:`)
                        logger.error(error);
                    }
                }
            }

            return apiGetterFunction(...params);
        })().then(result => {
            if (mounted) {
                if (result.logout) {
                    logout();
                    return;
                }
                if (result.success && result.data) {
                    dataSetter(result.data);
                    return;
                }
                errorSetter(forceDefault ? defaultErrorMessage : result.message);
            }
        });
        return () => { mounted = false };
    }, watchParams);
}

export default cache;

